import React from "react";
import { useTranslation } from "react-i18next";
import Vision from "../../components/vision/Vision";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";

const VisionPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb title="Our Vision" t={t} />
      <Vision />
    </>
  );
};

export default VisionPage;
