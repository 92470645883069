import { useState } from "react";
import React, { createContext, useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { productItemHome } from "../../pages/home";
import { productData } from "../../pages/products/mockData";
import { store } from "../store";
import Banner from "./banner/Banner";
import BestSeller from "./best-seller/BestSeller";
import Brands from "./brands/Brands";
import CategoriesItems from "./categories-items/CategoriesItems";
import CostInfo from "./cost-info/CostInfo";
import FeaturedProducts from "./featured-products/FeaturedProducts";
import Network from "./network/Network";
import ProductsCategory from "./products-category/ProductsCategory";
import ProductItem from "./products/product/ProductItem";
import Products from "./products/Products";
import ServiceList from "./service-list/ServiceList";
import TodayDeals from "./today-deals/TodayDeals";
import TrendingProducts from "./trending-products/TrendingProducts";
import AddingShow from "./network/AddingShow";
import HandicraftImage from "./handicraft-image/HandicraftImage";
import ExceptionalCard from "./exceptional/ExceptionalCard";
import CatagProduct from "./CatagPro/CatagProduct";
import axios from "axios";
import { base_url } from "../../server";
import PopularProduct from "./popular";

import etg from "../../assets/img/brands/etg.png";
import kynoch from "../../assets/img/brands/kynoch.png";
import falcon from "../../assets/img/brands/falcon.png";
import oemff from "../../assets/img/brands/oemff.png";
import artboard from "../../assets/img/brands/artboard.jpg";
import bayer from "../../assets/img/brands/bayer.jpg";
import dhanuka from "../../assets/img/brands/dhanuka.jpg";
import namdhari from "../../assets/img/brands/namdhari.jpg";

import bgAttach from "../../assets/img/skrills-1.jpg";

import { Link } from "react-router-dom";
import Innovative from "./innovative/Innovative";
import SaleProduct from "./sale-product/SaleProduct";
import Explore from "./explore/Explore";
import Values from "./values/Values";
import Expertise from "./expertise/Expertise";
import Blog from "./blog/Blog";
import CustomerView from "./customer-view/CustomerView";
import ProductRange from "./product-range/ProductRange";
import RequestCall from "./request-call/RequestCall";

function Home() {
  const productData = useContext(productItemHome);
  const baseUrl = base_url();
  const [data, setData] = useState(null);
  const getata = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/filter/categ`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getata();
  }, []);
  return (
    <>
      <Banner />
      <ServiceList />
      <ProductsCategory />
      <PopularProduct />
      {/* <Products productData={productData} /> */}

      <FeaturedProducts />
      <ProductRange />
      {/* <Values /> */}
      <Expertise />
      {/* <SaleProduct /> */}
      {/* <Explore /> */}

      {data &&
        data?.slice(0, 3).map((item, i) => {
          return <CatagProduct key={i} item={item} />;
        })}

      {/* <TrendingProducts /> */}
      {/* <TodayDeals /> */}
      <section className="sellerCategorySec d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <CategoriesItems />
            </div>
          </div>
        </div>
      </section>

      {/* <ExceptionalCard /> */}
      {/* <Innovative />  */}
      <Network />  
      <RequestCall />  
      <CustomerView />
      <Blog /> 
      <div
        className="attachmentImg"
        style={{
          backgroundImage: `url(${bgAttach})`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          height: "400px",
          marginTop: "40px",
        }}
      >
        <div className="container">
          <div className="broucherCard">
            <h3>Download latest brochure</h3>
            <p>
              Go through our product range on the go, learn about our endeavors,
              case studies & more. Tell us where should we send your FREE copy?
            </p>
            <form className="newsletter-form">
              <input
                type="email"
                className="input-newsletter"
                placeholder="Enter your email"
                name="email"
                required
                autoComplete="off"
              />
              <button type="button">Subscribe</button>
              <div id="validator-newsletter" className="form-result" />
            </form>
          </div>
        </div>
      </div>
      <Brands />
      <section className="brandsSec bg-white pb-0">
        <div className="container-fluid">
          <div className="brandsHeader">
            <div className="fisherman-content">
              <h3>top brands</h3>
            </div>
          </div>
          <div className="brandsItem">
            <ul>
              <li>
                <Link to={"/"}>
                  <img src={etg} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={falcon} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={kynoch} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={oemff} alt="Brand" title="Brand" />
                </Link>
              </li>

              <li>
                <Link to={"/"}>
                  <img src={artboard} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={bayer} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={dhanuka} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={namdhari} alt="Brand" title="Brand" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* <BestSeller /> */}
      {/* <AddingShow /> */}
      {/* <HandicraftImage /> */}
      {/* <CostInfo /> */}
    </>
  );
}

export default Home;
