import React from "react";
import icon1 from "../../../assets/img/news-1.jpg";
import icon2 from "../../../assets/img/news-2.jpg";
import icon3 from "../../../assets/img/795948666.jpg";
import { Link } from "react-router-dom";
const value = [
  {
    id: 1,
    url: icon1,
    title: "Learn Basic Fire Safety Rules",
  },
  {
    id: 2,
    url: icon2,
    title: "Save Lives when Possible",
  },
  {
    id: 3,
    url: icon1,
    title: "Learn Basic Fire Safety Rules",
  },
  
];

const Blog = () => {
  return (
    <>
      <section className="blogSec bg-white">
        <div className="container-fluid">
          <div className="fisherman-content text-center">
            <h6 className="brandText">Palladium Safety Solutions Pvt Ltd</h6>
            <h3>Blogs post</h3>
          </div>
          <div className="row">
            {value.map((item) => {
              return (
                <div className="col-lg-4" key={item.id}>
                  <div className="blogCard">
                    <div className="icon">
                      <img src={item.url} alt={item.title} />
                      <span>Palladium Safety Solutions</span>
                    </div>
                    <div className="text">
                      <h4>{item.title}</h4>
                      <Link to="/">Read more</Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
