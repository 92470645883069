import React from "react";
import copyright from "../../../assets/img/bg-04-copyright.jpg";

const RequestCall = () => {
  return (
    <>
      <section
        className="requestCallSec"
        style={{ backgroundImage: `url(${copyright})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div className="requestForm">
                <h3>Have questions? </h3>
                <p>
                  We will be happy to help you. Please, fill this small form and
                  help our customer support manager to contact you with all the
                  details.
                </p>
                <form className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="number"
                        placeholder="Mobile Number"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Company Name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        placeholder="Message"
                        className="form-control"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <button className="btn submitBtn">
                        Request a callback
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RequestCall;
