import React from "react";
import expertImg from "../../../assets/img/9.webp";
import { Link } from "react-router-dom";
const data = [
  {
    id: 1,
    title: "Corporate Offices",
    value: "01",
  },
  {
    id: 2,
    title: "Multinational Companies",
    value: "02",
  },
  {
    id: 3,
    title: "Hospitals & Nursing Homes",
    value: "03",
  },
  {
    id: 4,
    title: "Educational Institutions",
    value: "04",
  },
  {
    id: 5,
    title: "Shopping Malls",
    value: "05",
  },
  {
    id: 6,
    title: "Industrial Units",
    value: "06",
  },
];

const Expertise = () => {
  return (
    <>
      <section className="expertiseSec">
        <div className="container-fluid">
          <div className="fisherman-content">
            <h6 className="brandText">Palladium Safety Solutions Pvt Ltd</h6>
            <h3>Industries we serve</h3>
            <p>
              Our integrity, service and transparency have helped us expand our
              wings in a short span of time. Industries we serve today
              include...
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="expertImg">
                <img
                  src={expertImg}
                  alt="Our Expertise"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="expertList">
                <h6>ULTIMATE PRODUCTS.</h6>
                
                <p>
                  Find the latest and greatest gadgets to enhance your
                  electronic devices.
                </p>
                <ul>
                  {data.map((item) => {
                    return (
                      <li key={item.id}>
                        <span>{item.value}</span>
                        <p>{item.title}</p>
                      </li>
                    );
                  })}
                </ul>
                <Link to="/" className="btn">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Expertise;
