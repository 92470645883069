import React from "react";
const data = [
  {
    id: 1,
    title: "Customer-Centric Approach",
    sections: [
      {
        id: "1.1",
        subtitle: "Directional Airflow Control",
        content:
          "We prioritize the satisfaction of our customers above all else. We listen to their needs, understand their challenges, and tailor our solutions to meet their specific requirements. By putting our customers first, we aim to build long-lasting relationships based on trust and mutual respect.",
      },
      {
        id: "1.2",
        subtitle: "Exceptional Service",
        content:
          "We go above and beyond to provide exceptional service at every touchpoint. From the initial consultation to post-sale support, our team is committed to delivering a seamless and hassle-free experience for our customers. We strive to exceed their expectations and set the standard for excellence in the HVAC industry.",
      },
    ],
  },
  {
    id: 2,
    title: "Innovation and Technology",
    sections: [
      {
        id: "2.1",
        subtitle: "Continuous Improvement",
        content:
          "We embrace innovation and technology to continuously improve our products and services. By staying abreast of the latest advancements in HVAC technology, we are able to offer cutting-edge solutions that enhance comfort, efficiency, and sustainability for our customers.",
      },
      {
        id: "2.2",
        subtitle: "Smart Solutions",
        content:
          "We leverage smart technology and data-driven insights to optimize the performance of HVAC systems. Whether it's implementing energy-efficient controls, predictive maintenance algorithms, or remote monitoring capabilities, we empower our customers to maximize system efficiency.",
      },
    ],
  },
  {
    id: 3,
    title: "Quality and Reliability",
    sections: [
      {
        id: "3.1",
        subtitle: "Superior Products",
        content:
          "We partner with leading manufacturers to source high-quality HVAC equipment and components. Our rigorous quality control processes ensure that every product we offer meets the highest standards of reliability, durability, and performance.",
      },
      {
        id: "3.2",
        subtitle: "Skilled Team",
        content:
          "Our team of technicians is highly skilled, trained, and certified to handle all aspects of HVAC installation, maintenance, and repair. With their expertise and attention to detail, we deliver superior results that our customers can rely on for years to come.",
      },
    ],
  },
  {
    id: 4,
    title: "Sustainability and Environmental Responsibility",
    sections: [
      {
        id: "4.1",
        subtitle: "Energy Efficiency",
        content:
          "We are committed to promoting energy efficiency and environmental sustainability through our HVAC solutions. By offering energy-efficient equipment, optimizing system performance, and promoting responsible usage practices, we help our customers reduce their carbon footprint and lower their operating costs.",
      },
      {
        id: "4.2",
        subtitle: "Green Practices",
        content:
          "We adhere to green practices throughout our operations, from reducing waste and conserving resources to recycling materials and minimizing our environmental impact. We believe that sustainability is not only good for the planet but also essential for the long-term success of our business and the communities we serve.",
      },
    ],
  },
  {
    id: 5,
    title: "Community Engagement and Social Responsibility",
    sections: [
      {
        id: "5.1",
        subtitle: "Community Partnerships",
        content:
          "We actively engage with our local communities through partnerships, sponsorships, and charitable initiatives. By giving back to the communities that support us, we strive to make a positive difference in the lives of others and contribute to the greater good.",
      },
      {
        id: "5.2",
        subtitle: "Ethical Conduct",
        content:
          "We conduct our business with the highest standards of ethics, integrity, and professionalism. We adhere to all applicable laws, regulations, and industry standards, and we treat our customers, employees, and partners with fairness, honesty, and respect.",
      },
    ],
  },
];

const Mission = () => {
  return (
    <>
      <div className="subAboutSec">
        <div className="container">
          {data.map((item) => {
            return (
              <div className="subAboutCard" key={item.id}>
                <h4>{item.title}</h4>
                <ul>
                  {item.sections.map((item) => {
                    return (
                      <li key={item.id}>
                        <p>
                          <strong>{item.subtitle}</strong> {item.content}{" "}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Mission;
