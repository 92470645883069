import React from "react";

function AboutCounters({ t }) {
  return (
    <>
      <div className="countersSection">
        <div className="container-fluid">
          <div className="chooseTitle">
            <h3>Take action</h3>
            <p>
              Would you like to browse our products or want to explore business
              opportunities? We’re happy to help.
            </p>
          </div>
          <div className="countContainer">
            <div className="countItem">
              <div className="count-wrapper">
                <h4 className="count-to">Explore Product Range</h4>
              </div>
            </div>

            <div className="countItem">
              <div className="count-wrapper">
                <h4 className="count-to">Dealership Enquiry</h4>
              </div>
            </div>

            <div className="countItem">
              <div className="count-wrapper line-height-1">
                <h4 className="count-to">Request a Call-back</h4>
              </div>
              
            </div>
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
    </>
  );
}

export default AboutCounters;
