import React from "react";
import arc from "../../assets/img/architect-bg.jpg";
import { Link } from "react-router-dom";
const jobVacancies = [
  {
    id: 1,
    position: "Executive Sales & Marketing",
    vacancy: 4,
  },
  {
    id: 2,
    position: "Store Executive",
    vacancy: 1,
  },
  {
    id: 3,
    position: "Production Engineer (Mechanical)",
    vacancy: 2,
  },
  {
    id: 4,
    position: "Production Engineer (Electrical & Electronics)",
    vacancy: 2,
  },
];

function Architect() {
  return (
    <>
      <section className="careersSec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7">
              <div className="chooseTitle">
                <h3>
                  Quality Fire Safety Solutions.
                  <span>Recommended by Top Architects.</span>
                </h3>
                <p>
                  From fire audit to fire equipments supply, installing &
                  maintenance Palladium is the most preferred choice of leading
                  architects & fire safety consultants.
                </p>
                <h4>A trusted partner with proven track record</h4>
                <p>
                  One of the key role of any Architect who design the building
                  or Fire Safety Consultant is to ensure that the clients are
                  fully prepared in case of fire and emergency and are fully
                  compliant with legislations.
                </p>
                <p>
                  To achieve this, synergy & trust between fire safety
                  consultants & vendors is an absolute necessity. This is the
                  reason why when it comes to Fire Safety Solutions, Architects
                  & Consultants put their trust on Palladium. With us they get a
                  trusted partner who provides 360 degree fire safety solutions
                  without failing on commitments, and their clients get quality
                  fire safety equipments & services at world’s lowest price,
                  guaranteed.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="careersInfo">
                <img src={arc} alt="Arc" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="careersNote">
                <h3>Trusted by names you trust</h3>
                <p>Our integrity, service and transparency have helped us expand our wings in a short span of time. Today Palladium fire safety equipments are being used by top ranking government institutions like Rashtrapati Bhawan & PMO and reputed brands like Pizza Hut, Tata AIG, Dominos to name few.</p>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Architect;
