import React from "react";
const data = [
  {
    "id": 6,
    "title": "Innovation and Technology Leadership",
    "sections": [
      {
        "id": "6.1",
        "subtitle": "Pioneering Solutions",
        "content": "We aspire to lead the industry in innovation by developing and implementing cutting-edge HVAC technologies that push the boundaries of performance, efficiency, and sustainability. Through continuous research and development, we aim to introduce groundbreaking solutions that address the evolving needs of our customers and the planet."
      },
      {
        "id": "6.2",
        "subtitle": "Smart Integration",
        "content": "We envision a future where HVAC systems seamlessly integrate with smart technologies and data analytics to optimize performance, enhance comfort, and reduce energy consumption. By harnessing the power of IoT (Internet of Things) and AI (Artificial Intelligence), we strive to create intelligent HVAC solutions that adapt to changing conditions and deliver superior results."
      }
    ]
  },
  {
    "id": 7,
    "title": "Environmental Stewardship and Sustainability",
    "sections": [
      {
        "id": "7.1",
        "subtitle": "Carbon-Neutral Solutions",
        "content": "We are committed to advancing environmental stewardship and sustainability through our HVAC solutions. Our vision includes developing carbon-neutral HVAC systems that minimize greenhouse gas emissions and contribute to the global effort to combat climate change."
      },
      {
        "id": "7.2",
        "subtitle": "Green Building Practices",
        "content": "We envision a future where green building practices are the norm, with energy-efficient HVAC systems playing a central role in creating healthy, comfortable, and sustainable indoor environments. By promoting green building certifications and standards, we aim to drive widespread adoption of eco-friendly HVAC technologies."
      }
    ]
  },
  {
    "id": 8,
    "title": "Customer-Centric Excellence",
    "sections": [
      {
        "id": "8.1",
        "subtitle": "Exceptional Experiences",
        "content": "We are dedicated to delivering exceptional experiences for our customers at every touchpoint. Our vision includes providing personalized service, expert advice, and proactive support to ensure that our customers' HVAC needs are met with excellence and professionalism."
      },
      {
        "id": "8.2",
        "subtitle": "Long-Term Partnerships",
        "content": "We aspire to build long-term partnerships with our customers based on trust, transparency, and mutual respect. By understanding their unique challenges and goals, we aim to become their trusted advisor and partner in achieving success."
      }
    ]
  },
  {
    "id": 9,
    "title": "Global Impact and Influence",
    "sections": [
      {
        "id": "9.1",
        "subtitle": "Industry Leadership",
        "content": "We aim to be recognized as a global leader in the HVAC industry, setting the standard for excellence in innovation, quality, and service. Through thought leadership, collaboration, and advocacy, we seek to influence industry trends and shape the future of HVAC technology and practice."
      },
      {
        "id": "9.2",
        "subtitle": "Positive Impact",
        "content": "We envision making a positive impact on society and the environment through our business practices and solutions. By promoting energy efficiency, indoor air quality, and sustainability, we strive to improve the quality of life for people around the world and leave a lasting legacy for future generations."
      }
    ]
  }
];

const Vision = () => {
  return (
    <>
      <div className="subAboutSec">
        <div className="container">
          {data.map((item) => {
            return (
              <div className="subAboutCard" key={item.id}>
                <h4>{item.title}</h4>
                <ul>
                  {item.sections.map((item) => {
                    return (
                      <li key={item.id}>
                        <p>
                          <strong>{item.subtitle}</strong> {item.content}{" "}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Vision;
