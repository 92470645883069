
import React from "react";
import Rating from "../../../shared/rating/Rating";
import { Link } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
const data = [
  {
    id: 1,
    title: "01",
    description:
      "For me, safety of my people is paramount and hence I only trust Palex fire safety equipments. With continuous support and maintenance they actually ensure that system we have received never fails.",
    name: "Mr. Sanjay Thakur",
    address: "Assistant Manager – Maintenances",
  },
  {
    id: 2,
    title: "02",
    description:
      "I always wonder how could they afford to sell such fail-proof high quality fire extinguisher at such a low price! Then I realized it’s not about lowering quality but about optimizing production process. Hats off!",
    name: "Mr. Koti",
    address: "Procurement head (Mewar Traders & Engineers)",
  },
  
];

const AcrossClient = () => {
  return (
    <>
      <section className="customerViewSec" style={{backgroundColor: "#d5e1e6"}}>
        <div className="container-fluid">
          <div className="fisherman-content">
            <h6 className="brandText">Palladium Safety Solutions Pvt Ltd</h6>
            <h3>Serving 1000+ clients across globe            </h3>
            <p>
              See what our satisfied customers have to say about our electronic
              accessories.
            </p>
          </div>
          <div className="row">
            {data.map((item) => {
              return (
                <div className="col-md-6" key={item.id}>
                  <div className="customerCard">
                    <Rating />
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    <div className="user">
                      <strong className="d-block">{item.name}</strong>
                      <span>{item.address}</span>
                    </div>
                    <div className="readMore">
                      <Link to="/" className="btn">
                        Read More
                      </Link>
                      <div className="icon">
                        <GoArrowUpRight />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default AcrossClient

