import React from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import "./Menus.css";
import { useGetMenuListQuery } from "../../../components/products/productSlice";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { base_url } from "../../../server";
// const categoriesData = ["Cabinet", "Chair", "Lamp", "Sofa", "Stool", "Table"]
function Menus() {
  const isLogin = window.localStorage.getItem("isLogin");
  // const { data, isSuccess } = useGetMenuListQuery()
  const { data: categoryData } = useGetMenuListQuery();

  const [data, setdata] = useState(null);
  const baseUrl = base_url();
  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/public`, {
        withCredentials: true,
      });
      setdata(res.data);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <nav className="navbar navbarItem navbar-expand-md navbar-light">
        <div
          className="collapse navbar-collapse mean-menu show"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li className="nav-item mobileHide">
              <Link to="/" className="nav-link">
                {t("Home")}
              </Link>
            </li>
            <li className="nav-item mobileHide">
              <Link to="/about" className="nav-link">
                {t("About Us")}
              </Link>
            </li>
            {/* <div className="dropdown categoryDropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </button>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <Link to={`/about`} className="nav-link">
                    Who we are
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={`/mission`} className="nav-link">
                    Our Mission
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={`/vision`} className="nav-link">
                    Our Vision
                  </Link>
                </li>

              </ul>
            </div> */}
            <div className="dropdown categoryDropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Our Products
              </button>
              <ul className="dropdown-menu">
                {data?.map((item, i) => {
                  return (
                    <li className="nav-item" key={i}>
                      <Link
                        to={`/product/category/${item.uid}/${item?.slug}`}
                        // to={`/`}
                        className="nav-link"
                      >
                        {item?.name}
                        <i className="bx bx-chevron-down"></i>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* {data &&
            data?.map((item, i) => {
              return (
                <li className="nav-item mobileHide perent" key={i}>
                  <Link
                    // to={`product/category/${item?.title.uid}/${item?.title?.slug}`}
                    to={`/}`}
                    className="nav-link"
                  >
                    {item?.title?.name}
                    <i className="bx bx-chevron-down"></i>
                  </Link>

                  {item?.Submenu?.length > 0 && (
                    <ul className={`dropdown-menu dropAgro ${i}`}>
                      <div className="row">
                        <div className="col-lg-3 width-100pr">
                          <div
                            className="menuList"
                            style={{ paddingTop: "0px" }}
                          >
                            <ul>
                              {item?.Submenu &&
                                item?.Submenu?.map((item) => {
                                  return (
                                    <li className="nav-item" key={item?._id}>
                                      <Link
                                        to={`product/category/${item?._id}`}
                                        className="nav-link sub_menu_Text"
                                      >
                                        <FiChevronRight /> {item?.name}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ul>
                  )}
                </li>
              );
            })}
             */}
            {/* 
          
          <li className="nav-item mobileHide">
            <Link to="/products" className="nav-link">
              {t("All Products")}
              <i className="bx bx-chevron-down"></i>
            </Link>
          </li>
          <li className="nav-item mobileHide">
            <Link to="/photo-gallery" className="nav-link">
              Photo Gallery
              <i className="bx bx-chevron-down"></i>
            </Link>
          </li>

          <li className="nav-item mobileHide">
            <Link to="/" className="nav-link">
              Blogs
              <i className="bx bx-chevron-down"></i>
            </Link>
          </li>
          <li className="nav-item mobileHide">
            <Link to="/" className="nav-link">
              Shop
              <i className="bx bx-chevron-down"></i>
            </Link>
          </li>
          <li className="nav-item mobileHide">
            <Link to="/contact" className="nav-link">
              Explore NOW
              <i className="bx bx-chevron-down"></i>
            </Link>
          </li> */}
            <li className="nav-item mobileHide">
              <Link to="/contact" className="nav-link">
                Contact Us
                <i className="bx bx-chevron-down"></i>
              </Link>
            </li>

            {isLogin === true && (
              <li className="nav-item ">
                <Link to="/" className="nav-link">
                  Services
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Menus;
