import React from "react";
import about1 from "../../../assets/img/about1.webp";
import about2 from "../../../assets/img/about2.webp";

function AboutChoose({ t }) {
  return (
    <>
      <section className="featuresSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="chooseTitle">
                <h6 className="brandText">
                  Palladium Safety Solution Pvt. Ltd.
                </h6>
                <h3>
                  Integrity, transparency and care –{" "}
                  <span>the soul of our work</span>
                </h3>
                <p>
                  At palladium, it is our firm belief that the fire safety
                  system should not come at a sky-high price, thus we deliver
                  the products at the cheapest price range with complete
                  transparency & integrity.
                </p>
                <p>
                  In the following video, Manish Khatana – founder of Palladium,
                  is sharing what makes us different and an undisputed leader in
                  this segment.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-box bg-white">
                <div className="feature-box-content">
                  <p>
                    {/* {t('inabout')} */}
                    Trusted by High Govt. Offices & Industries
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-box bg-white">
                <i className="icon-shipped" />
                <div className="feature-box-content">
                  <p>
                    {/* {t('inabout')} */}
                    An ISO 9001:2015 Certified Company
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="feature-box bg-white">
                <div className="feature-box-content">
                  <p>
                    {/* {t('inabout')} */}
                    Good Quality Products Yet Lowest Prices
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-box bg-white">
                <div className="feature-box-content">
                  <p>
                    {/* {t('inabout')} */}
                    Zero Defect or Free Replacement
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutChoose;
