import React from "react";
import { useTranslation } from "react-i18next";
function Network() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="networkSec">
        <div className="container">
          <div className="networkItem">
            <div className="fisherman-content">
              <h6 className="brandText">Palladium Safety Solutions Pvt Ltd</h6>
              <h3>{t("Words of testimony")} </h3>
            </div>
            <ul className="namedd">
              <li>
                <div className="networkNumber">
                  <p>
                    For me, safety of my people is paramount and hence{" "}
                    <span className="netColor">
                      I only trust Palex fire safety equipments.
                    </span>{" "}
                    With continuous support and maintenance they actually ensure
                    that system we have received never fails.
                  </p>
                  <h5>Mr. Sanjay Thakur</h5>
                  <span className="d-block">Assistant Manager</span>
                  <span>(Maintenances)</span>
                </div>
              </li>
              <li>
                <div className="networkNumber">
                  <p>
                    I always wonder how could they afford to sell such
                    <span className="netColor">
                      fail-proof high quality fire extinguisher at such a low
                      price!
                    </span>{" "}
                    Then I realized it's not about lowering quality but about
                    optimizing production process. Hats off!
                  </p>
                  <h5>Mr. Koti</h5>
                  <span className="d-block">Procurement head</span>
                  <span>(Mewar Traders & Engineers)</span>
                </div>
              </li>
              <li>
                <div className="networkNumber">
                  <p>
                    <span className="netColor">
                      I would not hesitate to recommend Palladium
                    </span>{" "}
                    to another company for the supply of their fire safety
                    inspections and equipments. They are a rare breed with
                    professionalism, flexibility, and right attitude.
                  </p>
                  <h5>Stephen Ngige Gichia</h5>
                  <span className="d-block">Clemotex Engineering Ltd</span>
                  <span>(Zambia)</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Network;
