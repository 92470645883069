import React, { useEffect } from "react";
import TopHeader from "./top-header/TopHeader";
import SearchBox from "./search-box/SearchBox";
import { isMobile } from "react-device-detect";

import "./Header.css";
import Menus from "./menu/Menus";
import { useState } from "react";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import { base_url } from "../../server";

function Header({ changeLang }) {
  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const baseUrl = base_url();
  const fixedHeader = () => {
    if (window.scrollY >= 80) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  window.addEventListener("scroll", fixedHeader);

  const [state, setState] = useState({
    front_top_message: "",
    icon: "",
  });

  const onMenuButtonClicked = () => {
    if (isMobile) {
      setShowMenu(!showMenu);
    }
  };
  const location = useLocation();

  return (
    <>
      {/* <button type="button" onClick={()=>{changeLang('en')}}>EEE</button>
                    <button type="button" onClick={()=>{changeLang('de')}}>ARR</button> */}
      <header
        className={`${
          isSticky
            ? "sticky active"
            : `sticky ${location.pathname === "/" ? "" : "diff"}`
        }`}
      >
        <TopHeader state={state} changeLange={changeLang} />
        <SearchBox val={state} showMenu={onMenuButtonClicked} />
        {!isMobile && (
          <section className="navbarArea">
            <div className="abaris-nav">
              <div className="container">
                <div className="menuCard">
                  <div className="allCategoryDrop">
                    <span>categories</span>
                    <i className="ri-menu-line"></i>

                    <div className="categoryList">
                      <ul>
                        <li>
                          <Link to="/products">Baby bottles</Link>
                        </li>
                        <li>
                          <Link to="/products">Breastfeeding Accessories</Link>
                        </li>
                        <li>
                          <Link to="/products">Bibs & Cloths</Link>
                        </li>
                        <li>
                          <Link to="/products">Teethers & Pacifiers</Link>
                        </li>
                        <li>
                          <Link to="/products">Feeding Essentials</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </section>
        )}
      </header>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog inquiryModal">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Inquiry Now
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="mb-0" action="#">
                <div className="form-group mb-3">
                  <label className="mb-1" htmlFor="contact-name">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contact-name"
                    name="name"
                    required
                    placeholder="Please Enter Your Name"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="mb-1" htmlFor="contact-email">
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="contact-email"
                    name="email"
                    required
                    placeholder="email@example.com"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="mb-1" htmlFor="contact-email">
                    Mobile
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="contact-email"
                    name="phone"
                    required
                    placeholder="Enter Your Mobile Number"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="mb-1" htmlFor="contact-message">
                    Message
                  </label>
                  <textarea
                    cols={30}
                    rows={4}
                    id="contact-message"
                    className="form-control"
                    name="message"
                    required
                    defaultValue={""}
                    placeholder="Enter Your Message"
                  />
                </div>
                <div className="form-footer mb-0">
                  <button
                    type="button"
                    className="btn btn-primary font-weight-normal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
