import React from "react";
import Careers from "../../components/careers/Careers";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

function CareersPage() {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb title="Careers" t={t} />
      <Careers />
    </>
  );
}

export default CareersPage;
