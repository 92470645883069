import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import home1 from "../../../assets/img/banner/one.jpg";
import home2 from "../../../assets/img/banner/two.png";
import home3 from "../../../assets/img/banner/three.png";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      console.log("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* <section className="bannerSection">
        <Slider {...settings}>
          {data &&
            data.slice(0, 2).map((item) => {
              return (
                item?.image?.url && (
                  <Link to={item?.url || ""} key={item._id}>
                    <div className="bannerItem">
                      <img src={item?.image.url} className="img-fluid" />
                      <div className="bannerText">
                        <h2>Palladium Safety Solutions Pvt Ltd</h2>
                      </div>
                    </div>
                  </Link>
                )
              );
            })}
        </Slider>
      </section> */}

      <section className="bannerSection">
        <Slider {...settings}>
            <div className="bannerItem">
          <Link to={"/"}>
              <img src={home1} className="img-fluid" />
              <div className="bannerText">
                <h2>Most Cost-Effective Guarantee
                Fire Safety Solutions in India</h2>
                <ul>
                  <li>Rigorously tested</li>
                  <li>Trusted by hundreds of reputed clients</li>
                  <li>Unbeatable price</li>
                </ul>
                <Link to="/" className="btn">
                  Book Consult
                </Link>
              </div>
          </Link>
            </div>
          {/* <Link to={"/"}>
            <div className="bannerItem">
              <img src={home2} className="img-fluid" />
              <div className="bannerText">
                <h2>Making Every Season Feel Just Right</h2>
                <h4>Solution for Comfort and Reliability</h4>
                <p>
                  Palladium Safety Solutions Pvt Ltd is a leading provider of
                  comprehensive HVAC (Heating, Ventilation, and Air
                  Conditioning) solutions for commercial and residential
                  properties. With a commitment to excellence, innovation, and
                  sustainability...
                </p>
                <Link to="/" className="btn">
                  Book an appointment
                </Link>
              </div>
            </div>
          </Link>
          <Link to={"/"}>
            <div className="bannerItem">
              <img src={home3} className="img-fluid" />
              <div className="bannerText">
                <h2>View Your Property at any time and any where</h2>
                <h4>Solution for Comfort and Reliability</h4>
                <p>
                  Palladium Safety Solutions Pvt Ltd is a leading provider of
                  comprehensive HVAC (Heating, Ventilation, and Air
                  Conditioning) solutions for commercial and residential
                  properties. With a commitment to excellence, innovation, and
                  sustainability...
                </p>

                <Link to="/" className="btn">
                  Book Consult
                </Link>
              </div>
            </div>
          </Link> */}
        </Slider>
      </section>
    </>
  );
}

export default Banner;
