import React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import Mission from "../../components/mission/Mission";

const MissionPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb title="Our Mission" t={t} />
      <Mission />
    </>
  );
};

export default MissionPage;
