import React from "react";

// import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="storyText">
                <h6>Welcome</h6>
                <h3>
                  Amicable & highly affordable fire
                  <span> safety solutions</span>
                </h3>

                <p>
                  Palladium was founded in 2011 with an aim to provide people
                  across India and around the globe good quality fire safety
                  system at absolutely affordable prices.
                </p>
                <p>
                  Many years of design, development & production optimization
                  have helped us achieve our goal and today we have cemented our
                  position as India’s leading fire safety solutions manufacturer
                  & supplier with a proven track record of quality products,
                  superior service and lowest prices in the market.
                </p>
                <p>
                  That's not all, our constant innovation, manufacturing
                  expertise and best after sale service has established us as a
                  trend-setter in the fire safety industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
