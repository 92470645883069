import React from "react";
import Rating from "../../../shared/rating/Rating";
import { Link } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
const data = [
  {
    id: 1,
    title: "01",
    description:
      "I was just like to say thank you from the bottom of my heart, I know from an occupational point of view, that not very many of our office staff receive the laud they fit.",
    name: "Robert Smith",
    address: "Customer from USA",
  },
  {
    id: 2,
    title: "02",
    description:
      "A huge thank you for presenting our fire last tuesday. Your High Volume Pump really was a game changer in bearing the fire to one office. Thank you for crossing the border to come.",
    name: "Allen Lyn",
    address: "Customer from France",
  },
  {
    id: 3,
    title: "03",
    description:
      "I was very impressed by the company services once I faced some fire issues and I called their emergency number. The fire brigs are present there only in 15 minutes.",
    name: "Peter Rope",
    address: "Customer from USA",
  },
];
const CustomerView = () => {
  return (
    <>
      <section className="customerViewSec">
        <div className="container-fluid">
          <div className="fisherman-content">
            <h6 className="brandText">Palladium Safety Solutions Pvt Ltd</h6>
            <h3>Happy Clients</h3>
            <p>
              See what our satisfied customers have to say about our electronic
              accessories.
            </p>
          </div>
          <div className="row">
            {data.map((item) => {
              return (
                <div className="col-md-4" key={item.id}>
                  <div className="customerCard">
                    <Rating />
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    <div className="user">
                      <strong className="d-block">{item.name}</strong>
                      <span>{item.address}</span>
                    </div>
                    <div className="readMore">
                      <Link to="/" className="btn">
                        Read More
                      </Link>
                      <div className="icon">
                        <GoArrowUpRight />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomerView;
