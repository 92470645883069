import React from "react";

import home2 from "../../../assets/img/about-1.jpg";

import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
function ServiceList() {
  return (
    <>
      <section className="serviceList sectionPD">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5">
              <div className="aboutImg">
                <img src={home2} alt="About" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="aboutWooden">
                <span>welcome</span>
                <h2>Shaping the future of fire protection</h2>
                <p>
                  Since 2011, Palladium is helping people fight the fire with
                  world-class fire safety equipment made available at an
                  unbeatable low price.
                </p>
                <p>
                  Many years of design, development & production optimization
                  has helped us manufacture & supply a vast range of good
                  quality Fire Alarm Systems, Fire Extinguishers and Fire
                  Fighting Equipments at a lowest market price.
                </p>
                <p>
                  That's not all, our constant innovation, manufacturing
                  expertise and best after sale service has established us as a
                  trend-setter in the fire safety industry.
                </p>
                <Link to={"/about"} className="btn">
                  about company
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
