import React from "react";
import Architect from "../../components/architect/Architect";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

function ArchitectPage() {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb title="Architect" t={t} />
      <Architect />
    </>
  );
}

export default ArchitectPage;
