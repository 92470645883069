import React from "react";
import { Link } from "react-router-dom";
const jobVacancies = [
  {
    id: 1,
    position: "Executive Sales & Marketing",
    vacancy: 4,
  },
  {
    id: 2,
    position: "Store Executive",
    vacancy: 1,
  },
  {
    id: 3,
    position: "Production Engineer (Mechanical)",
    vacancy: 2,
  },
  {
    id: 4,
    position: "Production Engineer (Electrical & Electronics)",
    vacancy: 2,
  },
];

function Careers() {
  return (
    <>
      <section className="careersSec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="careersInfo">
                <div className="chooseTitle">
                  <h3>Build the steps to your brighter future with us</h3>
                  <p>
                    If you share our vision to help India keep safe from fire
                    hazards and if you are the one who is ready to work
                    diligently under pressure then a bright career at Palladium
                    is waiting for you.
                  </p>
                </div>

                <div className="careersNote">
                  <h5>Current openings</h5>
                  <div className="careerCard">
                    {jobVacancies.map((item) => {
                      return <div className="careerItem" key={item.id}>
                        <div className="item">
                          <span>POST</span>
                          <h5>{item.position}</h5>
                        </div>
                        <div className="item">
                          <span>POST</span>
                          <h5>{item.vacancy}</h5>
                        </div>
                        <div className="item">
                          <Link to="/">Apply </Link>
                        </div>
                      </div>;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careers;
