import React from "react";
import img1 from "../../../assets/img/fire-alarm-system.png";
import img2 from "../../../assets/img/fire-extinguishers.png";
import img3 from "../../../assets/img/fire-fighting-equipments.png";
import img4 from "../../../assets/img/electrical-panels.png";
const data = [
  {
    id: 1,
    category: "Fire Alarm System",
    desc: "Detect smoke, fire or heat early to safeguard human lives and property.",
    url: img1,
    items: [
      { id: 1.1, name: "Panels" },
      { id: 1.2, name: "Detectors" },
      { id: 1.3, name: "Hooter" },
      { id: 1.4, name: "Siren" },
      { id: 1.5, name: "Public Addressable" },
    ],
  },
  {
    id: 2,
    category: "Fire Extinguisher",
    desc: "Controls the fire and work as a pollutant limiter in case of an accident.",
    url: img2,
    items: [
      { id: 2.1, name: "ABC Dry Powder" },
      { id: 2.2, name: "Water CO2" },
      { id: 2.3, name: "Mechanical Foam" },
      { id: 2.4, name: "Dry Chemical Powder" },
      { id: 2.5, name: "Clean Agent" },
      { id: 2.6, name: "Carbon Dioxide" },
      { id: 2.7, name: "Ceiling Mounted" },
    ],
  },
  {
    id: 3,
    category: "Fire Fighting Equipments",
    desc: "Prevention and protection by working as a shield for firefighters.",
    url: img3,
    items: [
      { id: 3.1, name: "Hose Reel Drum" },
      { id: 3.2, name: "Hose Box" },
      { id: 3.3, name: "Sprinklers" },
      { id: 3.4, name: "Hydrant Valve & Accessories" },
      { id: 3.5, name: "Brigade" },
    ],
  },
  {
    id: 4,
    category: "Electrical Panel",
    desc: "Controller of the notification system to prevent fire.",
    url: img4,
    items: [
      { id: 4.1, name: "Direct Online Starter" },
      { id: 4.2, name: "Star Delta Starter" },
      { id: 4.3, name: "Diesel Engine Starter" },
    ],
  },
];

const ProductRange = () => {
  return (
    <>
      <section className="productRangeSec bgSec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h6 className="brandText">
                  Palladium Safety Solutions Pvt Ltd
                </h6>
                <h3>Our product range</h3>
              </div>
            </div>
            {data.map((item) => {
              return (
                <div className="col-md-6" key={item.id}>
                  <div className="rangeCard">
                    <div className="rangeImg">
                      <img
                        src={item.url}
                        alt={item.category}
                        className="img-fluid"
                      />
                    </div>
                    <div className="rangeContent">
                      <h4>{item.category}</h4>
                      <p>{item.desc}</p>
                      <ul>
                        {item.items.map((item) => {
                          return <li key={item.id}>{item.name}</li>;
                          
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductRange;
